import * as React from "react"
import Layout from "../components/Layouts"
import Title from "../components/Title"
import { Link } from "gatsby"
import Dubai from "./places/dubai"

// markup
const IndexPage = () => {
  return (
   <Layout>
     <Dubai />
   </Layout>
  )
}

export default IndexPage
